import DatePicker from 'vue2-datepicker';
import appStrings from '../../../../../utility/string.utility';
import party from '@/app/components/generalLedger/party';
import { format } from 'date-fns';
export default {
  name: '',
  props: ['fmsMeterData', 'meterId', 'addEditFlag'],
  components: {
    DatePicker,
    party
  },
  data() {
    return {
      responseMsg: '',
      loader: false,
      editMode: false,
      respMessage: null,
      isSuccess: false,
      showAlert: false,

      fmsMeterDetailsData: [
        {
          id: 0,
          fms_prj_name: null,
          fms_prj_id: null,
          entity_type: null,
          entity_id: null,
          meter_no: null,
          active: true,
          start_date: format(new Date(), 'dd-MMM-yyyy'),
          end_date: null,
          meter_type: 'ELEC-DG',
          meter_code: 'COMBINED METER',
          party_id: null,
          party_type: 'CUSTOMER',
          party_name: null,
          customer_site_name: null,
          ownership_type_vset: 'OWNER',
          owner_value_meaning: 'OWNER',
          load: null,
          load_uom_meaning: null,
          customer_site_id: null
        }
      ],
      showFlag: false,
      fmsMeterDetailsFields: [
        // {
        //   key: 'add',
        //   class: this.addEditFlag ? 'd-none' : ''
        // },
        // {
        //   key: 'remove',
        //   class: this.addEditFlag ? 'd-none' : ''
        // },
        {
          key: 'fms_prj_name',
          label: 'Project Name'
        },
        {
          key: 'entity_type',
          label: 'Entity Type'
        },
        {
          key: 'unit_name',
          label: 'Unit/Subunit'
        },
        {
          key: 'meter_no',
          label: 'Meter No'
        },
        {
          key: 'meter_code',
          label: 'Meter Type'
        },
        {
          key: 'owner_value_meaning',
          label: 'Ownership Type'
        },
        {
          key: 'party_type',
          label: 'Party Type'
        },
        {
          key: 'party_name',
          label: 'Party Name'
        },
        {
          key: 'customer_site_name',
          label: 'Party Site'
        },
        {
          key:'site_address',
          label:'Site Address'
        },
        {
          key: 'active',
          label: 'Active'
        },
        {
          key: 'load',
          label: 'Load'
        },
        {
          key: 'load_uom_meaning',
          label: 'Load UOM'
        },
        {
          key: 'start_date',
          label: 'Start Date'
        },
        {
          key: 'end_date',
          label: 'End Date'
        }
      ],
      showValueSetModal: false,
      specIndex: null,
      showPartyModal: false,
      date: null,
      
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'save') {
          if (this.editMode === true){
            this.postFmsMeterDetails();
          } else{
            this.$bvToast.toast('Please enable editMode', {
              title: 'Alert',
              variant: 'success',
              solid: true
            });
          }
         
        }
      }
    });
    if (this.meterId) {
      this.getFmsMeterDetailsById(this.meterId);
    } else {
      this.editMode = true;
    }
  },

  methods: {
    checkUnitorSubUnit(entityType, index) {
      this.specIndex = index;
      if (
        this.fmsMeterDetailsData[index].fms_prj_name &&
        this.fmsMeterDetailsData[index].entity_type
      ) {
        if (entityType === 'FMS_UNIT') {
          this.openValueSetModal('FMS_UNIT_DISPLAY_VSET', index);
        } else if (entityType === 'FMS_SUB_UNIT') {
          this.openValueSetModal('FMS_SUB_UNIT_DISPLAY_VSET', index);
        } else if (entityType === 'LEASE_UNIT') {
          this.openValueSetModal('LEASE_UNIT_VSET', index);
        }
      } else if (
        this.fmsMeterDetailsData[index].fms_prj_name &&
        !this.fmsMeterDetailsData[index].entity_type
      ) {
        alert('Please Select Entity Type first');
      } else if (
        !this.fmsMeterDetailsData[index].fms_prj_name &&
        this.fmsMeterDetailsData[index].entity_type
      ) {
        alert('Please Select Project Name first');
      } else {
        alert('Please Select Project Name and Entity Type first');
      }
      
    },
    getFmsMeterDetailsById(id) {
      let payload = {
        id: id
      };
      this.loading = true;
      this.$store
        .dispatch('masterData/getFmsMeterDetailsById', payload)
        .then(response => {
          if (response.status === 200) {
            this.fmsMeterDetailsData = [];
            this.fmsMeterDetailsData.push(response.data.data);

            // this.isSuccess = true;
            // this.respMessage = response.data.message;
          } else {
            // this.isSuccess = false;
            // this.respMessage = response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.specIndex = index;
      this.parent_value_set_id = null;
      this.showValueSetModal = true;
      if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_UNIT_DISPLAY_VSET ||
        this.vsetCode === appStrings.VALUESETTYPE.FMS_SUB_UNIT_DISPLAY_VSET ||
        this.vsetCode === appStrings.VALUESETTYPE.LEASE_UNIT_VSET
      ) {
        this.parent_value_set_id = this.fmsMeterDetailsData[index].fms_prj_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.CUSTOMER_SITE_VSET) {
        if(this.fmsMeterDetailsData[index].party_name===null) {
          alert('Please select Party Name first')
          this.showValueSetModal = false;
        } else {
          this.parent_value_set_id = this.fmsMeterDetailsData[index].party_id;
        }
        } else if (this.vsetCode ===appStrings.VALUESETTYPE.UNIT_TYPE){
          if(this.fmsMeterDetailsData[index].fms_prj_name === null){
            alert('Please select Project Name first')
            this.showValueSetModal =false;
          }
        }

      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.UNIT_TYPE) {
        this.fmsMeterDetailsData[this.specIndex].entity_type =
          item.value_meaning;
        // this.fmsMeterDetailsData[this.index].entity_type = item.value_code;
        this.fmsMeterDetailsData[this.specIndex].entity_id = null;
        this.fmsMeterDetailsData[this.specIndex].unit_name = null;

        // this.fmsMeterDetailsData[this.index].unitSubunit.value = null;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_UNIT_DISPLAY_VSET
      ) {
        this.fmsMeterDetailsData[this.specIndex].entity_id = item.fms_unit_id;
        this.fmsMeterDetailsData[this.specIndex].unit_name =
          item.unit_display_name;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_SUB_UNIT_DISPLAY_VSET
      ) {
        this.fmsMeterDetailsData[this.specIndex].unit_name =
          item.fms_sub_unit_name;
        this.fmsMeterDetailsData[this.specIndex].entity_id =
          item.fms_sub_unit_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_UNIT_VSET) {
        this.fmsMeterDetailsData[this.specIndex].entity_id =
          item.lease_prj_unit_id;
        this.fmsMeterDetailsData[this.specIndex].unit_name = item.unit_name;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.METER_TYPE) {
        this.fmsMeterDetailsData[this.specIndex].meter_type = item.value_code;
        this.fmsMeterDetailsData[this.specIndex].meter_code =
          item.value_meaning;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.CUSTOMER_VSET) {
        this.fmsMeterDetailsData[this.specIndex].party_name =
          item.customer_name;
        this.fmsMeterDetailsData[this.specIndex].party_id = item.customer_id;
        
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.OWNERSHIP_TYPE_VSET
      ) {
        this.fmsMeterDetailsData[this.specIndex].ownership_type_vset =
          item.value_code;
        this.fmsMeterDetailsData[this.specIndex].owner_value_meaning =
          item.value_meaning;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PRJ_INTERNAL) {
        this.fmsMeterDetailsData[this.specIndex].fms_prj_name = item.value_code;
        this.fmsMeterDetailsData[this.specIndex].fms_prj_id =
          item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.UOM) {
        this.fmsMeterDetailsData[this.specIndex].load_uom_meaning =
          item.value_meaning;
        this.fmsMeterDetailsData[this.specIndex].load_uom_id = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.CUSTOMER_SITE_VSET) {
        this.fmsMeterDetailsData[this.specIndex].customer_site_name =
          item.site_name;
        this.fmsMeterDetailsData[this.specIndex].customer_site_id =
          item.customer_site_id;
          this.fmsMeterDetailsData[this.specIndex].site_address=item.address
         
      }
      this.fmsMeterDetailsData = JSON.parse(
        JSON.stringify(this.fmsMeterDetailsData)
      );
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode, index) {
      this.specIndex = index;
      if (vsetCode === appStrings.VALUESETTYPE.UNIT_TYPE) {
        this.fmsMeterDetailsData[this.specIndex].entity_type = null;
      } else if (
        vsetCode === appStrings.VALUESETTYPE.FMS_UNIT_DISPLAY ||
        vsetCode === appStrings.VALUESETTYPE.FMS_SUB_UNIT_DISPLAY ||
        vsetCode === appStrings.VALUESETTYPE.LEASE_UNIT_VSET
      ) {
        this.fmsMeterDetailsData[this.specIndex].entity_id = null;
        this.fmsMeterDetailsData[this.specIndex].unit_name = null;
      } else if (vsetCode === appStrings.VALUESETTYPE.FMS_PRJ_INTERNAL) {
        this.fmsMeterDetailsData[this.specIndex].fms_prj_name = null;
        this.fmsMeterDetailsData[this.specIndex].fms_prj_id = null;
        this.fmsMeterDetailsData[this.specIndex].entity_id = null;
        this.fmsMeterDetailsData[this.specIndex].unit_name = null;
        this.fmsMeterDetailsData[this.specIndex].entity_type = null;
      } else if (vsetCode === appStrings.VALUESETTYPE.CUSTOMER_VSET) {
        this.fmsMeterDetailsData[this.specIndex].party_name = null;
        this.fmsMeterDetailsData[this.specIndex].party_id = null;
        this.fmsMeterDetailsData[this.specIndex].customer_site_name = null;
        this.fmsMeterDetailsData[this.specIndex].customer_site_id = null;
      } else if (vsetCode === appStrings.VALUESETTYPE.CUSTOMER_SITE_VSET) {
        this.fmsMeterDetailsData[this.specIndex].customer_site_name = null;
        this.fmsMeterDetailsData[this.specIndex].customer_site_id = null;
      } else if (vsetCode === appStrings.VALUESETTYPE.UOM){
        this.fmsMeterDetailsData[this.specIndex].load_uom_meaning = null;
        this.fmsMeterDetailsData[this.specIndex].load_uom_id =null;
       }
    },
    addNewRow() {
      this.fmsMeterDetailsData.push({
        id: 0,
        entity_type: null,
        entity_id: null,
        meter_no: null,
        active: true,
        start_date: format(new Date(), 'dd-MMM-yyyy'),
        end_date: null,
        meter_type: 'ELECTRICITY',
        party_id: null,
        party_type: 'CUSTOMER',
        party_name: null,
        ownership_type_vset: 'OWNER',
        owner_value_meaning: 'OWNER-1'
      });
    },
    removeRow(index) {
      this.fmsMeterDetailsData.splice(index, 1);
    },
    showHidePartyModal(flag, index) {
      this.specIndex = index;
      this.showPartyModal = flag;
    },
    showhideFmsMeterModal(flag) {
      this.addFmsMeterModal = flag;
    },
    postFmsMeterDetails() {
      let fmsMeterDetailsData = [];
      let count = 0;
      for (let i of this.fmsMeterDetailsData) {
        if (
          i.entity_type === null ||
          i.unit_name === null ||
          i.meter_no === null ||
          i.meter_type === null ||
          i.party_type === null ||
          i.party_name === null ||
          i.active === null ||
          i.start_date === null ||
          i.fms_prj_name === null ||
          i.customer_site_name === null
        ) {
          count++;
        }
      }
      if (count > 0) {
        alert('Please select mandatory fields ');
      } else {
        for (let i of this.fmsMeterDetailsData) {
          fmsMeterDetailsData.push({
            id: i.id,
            entity_type: i.entity_type,
            entity_id: i.entity_id,
            meter_no: i.meter_no,
            active: i.active,
            start_date: i.start_date,
            end_date: i.end_date,
            meter_type: i.meter_type,
            party_id: i.party_id,
            party_type: i.party_type,
            ownership_type_vset: i.ownership_type_vset,
            fms_prj_id: i.fms_prj_id,
            customer_site_id: i.customer_site_id,
            load: +i.load,
            load_uom: i.load_uom_meaning
          });
        }
        if (fmsMeterDetailsData.length > 0) {
          let payload = {
            add_meter_dtls: fmsMeterDetailsData
          };
          this.loading = true;
          this.$store
            .dispatch('masterData/postFmsMeterDetails', payload)
            .then(response => {
              if (response.status === 201) {
                this.showAlert = true;
                this.isSuccess = true;
                this.respMessage = response.data.message;
                this.fmsMeterDetailsData = [];
                this.fmsMeterDetailsData.push(response.data.data);
                this.editMode =false;
              } else {
                this.showAlert = true;
                this.isSuccess = false;
                this.respMessage = response.response.data.message;
              }
            })
            .catch(() => {
              this.loader = false;
            });
        }
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
